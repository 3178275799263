
export default {
  name: 'BaseYavagroLabel',
  props: {
    id: {
      type: String,
      required: true
    },
    name: {
      type: String,
      default: ''
    },
    theme: {
      type: String,
      default: ''
    }
  }
}
