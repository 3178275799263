
import BaseYavagroLabel from './BaseYavagroLabel.vue'

export default {
  name: 'BaseYavagroCourseCard',
  components: {
    BaseYavagroLabel
  },
  props: {
    id: {
      type: String,
      required: true
    },
    name: {
      type: String,
      default: ''
    },
    description: {
      type: String,
      default: ''
    },
    slug: {
      type: String,
      default: ''
    },
    labels: {
      type: Array,
      default: () => []
    },
    saleLabels: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      windowWidth: undefined
    }
  },
  computed: {
    allLabels() {
      return process.browser && this.windowWidth < 1440 ? [...this.labels, ...this.saleLabels] : []
    }
  },
  mounted() {
    if (process.browser) {
      this.windowResize()
      this.$nextTick(() => {
        window.addEventListener('resize', this.windowResize)
      })
    }
  },
  beforeDestroy() {
    if (process.browser) {
      window.removeEventListener('resize', this.windowResize)
    }
  },
  methods: {
    onClickCard() {
      this.$emit('on-click-card', this.id)
    },
    onClickLabel(id) {
      this.$emit('on-click-label', id)
    },
    windowResize() {
      this.windowWidth = window.innerWidth
    }
  }
}
