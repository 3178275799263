
import { Component, Prop, Vue } from 'nuxt-property-decorator'
import CoursesCard from './CoursesCard.vue'
import PageContainer from '~/components/Containers/PageContainer.vue'
import { ContainerOptions, CourseItemModel } from '~/model'

@Component({
  components: {
    CoursesCard,
    PageContainer
  }
})
export default class extends Vue {
  @Prop({
    default: () => {}
  })
  readonly containerOptions!: ContainerOptions

  @Prop({
    default: () => []
  })
  readonly coursesCards!: CourseItemModel[]
}
