
import { Component, Prop, Vue } from 'nuxt-property-decorator'
import BaseRabotaAgroCourseCard from '@rshb/rshb-vue-kit/src/projects/rabotaAgro/cards/BaseRabotaAgroCourseCard.vue'
import { CourseItemModel } from '~/model'
import BaseYavagroCourseCard from '~/components/UI/BaseYavagroCourseCard.vue'

@Component({
  components: {
    BaseYavagroCourseCard,
    BaseRabotaAgroCourseCard
  }
})
export default class extends Vue {
  @Prop({
    default: () => ({
      id: '',
      name: '',
      tags: [],
      slug: ''
    })
  })
  readonly coursesCard!: CourseItemModel

  get card() {
    const res = this.coursesCard
    res.labels = res.partner_tags
      ? res.partner_tags.map(c => ({
          ...c,
          id: '' + c.id,
          theme: c.is_sale ? 'green' : ''
        }))
      : []
    return res
  }
}
